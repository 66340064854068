import * as React from "react";

import Layout from 'src/components/layout';


export default function Page() {
    return (
        <Layout>

            <h1>
                Curriculum Vitae
            </h1>

            <h2>Skills</h2>

            <ul>
                <li>
                    <strong>Statistics</strong> Descriptive, analysis, scatter plots, histograms, Shapiro-Wilk test, t-test, Levene's test, Wilcoxon-signed rank test, hypothesis testing
                </li>
                <li>
                    <strong>R</strong> Fluent user, PLSR, MLR, cubist method, predictions, calibration of instrumentation, ggplot2, publishable plots
    
                </li>
                <li>
                    <strong>PCA</strong> Used for dimention reduction and identifying associated variables
                </li>
                <li>
                    <strong>Predictions</strong> Regression methods, PLSR, Cubist
                </li>
                <li>
                    <strong>MLR</strong> Used for identifying statistically significant variables and predictions
                </li>
                <li>
                    <strong>Complex equations</strong> Experienced with applications in chemistry and instrumentation
                </li>
                <li>
                    <strong>Python</strong> Beginner, PyCharm, Jupyter, scikit-learn
                </li>
                <li>
                    <strong>Visual Studio Code and GitHub</strong> Beginner, used to build my first website, I also have experience with peer review
                </li>
            </ul>

            <h2>Education</h2>
            <ul>
                <li>
                    <strong>PhD</strong> College of Engineering
                </li>
            </ul>

            <h2>Hobbies</h2>
            <ul>
                <li>
                    <strong>Horse rider</strong> 
                </li>
                <li>
                    <strong>Hiker</strong> 
                </li>
            </ul>
        </Layout>
    )
}
